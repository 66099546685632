import React, { useRef, useState, useEffect, Fragment } from 'react'
import { useCookies } from 'react-cookie'
import {applyAttributeUpdates, selectFeatures, selectIds, unselectAll, updateVertices, finishLine, deleteFeatures, createNewTable, addMapButton, toggleEditPane, getPages, hideOnClickOutside, clearUI} from '../../utils/esri-helpers'
import LoadingBox from '../design/loadingbox'
import { generateFilter, layerBinding, projectManagerEditLayers, globalState } from '../../utils/constants.js'
import debounce from 'lodash.debounce'

let equal = require('deep-equal');
let highlight = [];
// Save these vars for use in app
let form, view, draw, map, latestSelection, latestSelectKey, latestLoadingOrgs;

// hooks allow us to create a map component as a function
const EsriMap = ({ modules, center, paverReg, showDropzone, setShowDropzone, setShowQueryModal, setShowDiffModal, setShowExportModal, selection, setSelection, setShowTable, setSuspendTable, preSelection, setPreSelection, selectKey, setSelectKey, hoverKey, queryResult, setView, uploadSelect, loadingOrgs, setLoadingOrgs, setUpdateObj}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['paverMap'])
  let [prevTable, setPrevTable] = useState({})
  let [selectLoading, setSelectLoading] = useState(false)
  // This exists just to prompt a table re-draw
  // Not great code, but it works, maybe refactor this later
  let [tableTrigger, setTableTrigger] = useState([]) 

  // create a ref to element to be used as the map's container
  const mapEl = useRef(null)
  // Holding updated values so that subscriptions can still use these
  // God, I hate React sometimes
  latestSelection = selection
  latestSelectKey = selectKey
  latestLoadingOrgs = loadingOrgs

  // Destructured assignment of all the modules
  let {
    esriConfig, esriRequest, Map, WebMap, MapView, FeatureLayer, GraphicsLayer, SketchViewModel, LayerList, Legend, FeatureForm, FeatureTable, Draw, Graphic, webMercatorUtils, geometryEngineAsync, editLayers, boundaryFeatureLayer, boundaryGeo, extent, groupLayers, Basemap, BasemapGallery, VectorTileLayer, Search, ScaleBar, Tooltip
  } = modules

  let escFunction = (event, bypass) => {
    if(event.keyCode === 27 || bypass) {
      // If there's a selection, unselect
      if (selection.length > 0){
        unselectAll(setSelection)
        toggleEditPane(view, false)

        // If selection length is EXACTLY 1, clear the preselect as well
        if (preSelection.multiSelectMap && Object.keys(preSelection.multiSelectMap).length === 1){
          setPreSelection({})
        }
      } else {
        // Otherwise, just clear the preselect
        setPreSelection({})
      }
    }
  }

  let triggerOrgUpdate = (keys) => {
    // If no keys are passed in, do them all
    if (!keys){
      let allKeys = []
      Object.keys(editLayers).forEach((key) => {
        allKeys.push(key)
      })
      keys = allKeys
    } 
    // Update the data for the passed in keys
    const newUpdate = {}
    keys.forEach((key) => {
      newUpdate[key] = "updating"

      // Check to see if we need to include any other layers 
      for (let i in layerBinding){
        let layerList = layerBinding[i]
        if (layerList.indexOf(key) !== -1){
          // We found a bound layer, we need to update all of these
          for (let j in layerList){
            let layer = layerList[j]
            // IF it exists, hopefully these names don't change
            if (editLayers[layer]){
              newUpdate[layer] = "updating"
            }
          }
        }
      }
    })
    // Finally set the data
    //console.log("NEW UPDATE COMING IN", newUpdate)
    setUpdateObj(newUpdate)
    // And for every key, refresh the lines
    Object.keys(newUpdate).forEach(key => {
      if (editLayers[key]){
        editLayers[key].refresh()
      }
    })
  }

  let updateTable = (selection, setShowTable, setSuspendTable, selectKey, prevTable) => {
    if (selection.length >= 2){
      // Show the table, add warning if we had to cut it off at 2000 records
      let tableVal = true
      if (selection.length > 2000){
        tableVal = "warn"
      }
      setShowTable(tableVal)
      // If we're showing, update the feature table with the selection
      let itemArray = []
      let lockAll = false
      for (let item in selection){
        let thisItem = selection[item]
        itemArray.push(thisItem.attributes.OBJECTID)
        // Check for non-member features
        if (thisItem.attributes.MemberName !== paverReg.org){
          lockAll = true
        }
      }
      // Handle creating if the layer is valid
      if (editLayers[selectKey]){
        // Erase the old table and feature layer
        if (!!prevTable.tableLayer){
          prevTable.tableLayer.destroy()
        }
        if (!!prevTable.featureTable){
          prevTable.featureTable.destroy()
        }
        // Create the new
        const resultTable = createNewTable(paverReg, editLayers[selectKey], itemArray, selection, setSelection, Graphic, FeatureLayer, FeatureTable, form, view, editLayers, selectKey, setPreSelection, lockAll, setSelectLoading)
        setPrevTable(resultTable)
      }
    } else {
      // Hide the table
      setShowTable(false)
      // Remove suspend setting
      setSuspendTable(false)
    }
  }

  useEffect(() => {
    // TODO: Maybe we can use this to make the preselect mess in mapcontext.js less awful
    let queryKeys = Object.keys(queryResult)
    if (queryKeys.length > 0){
      // Set selection based on query
      // Get the key name 
      let queryKey = queryKeys[0]
      let queryItems = queryResult[queryKeys[0]]
      // Start the loading screen
      setSelectLoading(queryItems.length)
      // Go get 'em
      selectIds(paverReg.token, queryItems, editLayers[queryKey], queryKey, setPreSelection, editLayers, form, view, Graphic, selectFeatures, setSelection).then((result) => {
        // Whatever happens, end the loading screen
        setSelectLoading(false)
      })
    }
    //console.log("QUERY RESULT FIRING")
  }, [queryResult])

  // Select items when there's a new upload
  useEffect(() => {
    if (uploadSelect.ids){
      // Start the loading screen
      setSelectLoading(uploadSelect.ids.length)
      // Go get 'em
      selectIds(paverReg.token, uploadSelect.ids, editLayers[uploadSelect.layer], uploadSelect.layer, setPreSelection, editLayers, form, view, Graphic, selectFeatures, setSelection).then((result) => {
        // Whatever happens, end the loading screen
        setSelectLoading(false)
      })
    }
  }, [uploadSelect])

  // This is called "center" but it's really just a menu-based select
  // TODO: Fix this bad legacy naming convention
  useEffect(() => {
    if (!view) return
    if (!center) return

    let {items, key} = center
    // Start the loading screen
    setSelectLoading(items.length)
    // Also select this feature
    selectIds(paverReg.token, items, editLayers[key], key, setPreSelection, editLayers, form, view, Graphic, selectFeatures, setSelection).then((result) => {
      // Whatever happens, end the loading screen
      setSelectLoading(false)
    })
    //console.log("CENTER FIRING")
  }, [center])

  // Update feature table if we have a selection that's more than 1
  useEffect(() => {
    updateTable(selection, setShowTable, setSuspendTable, selectKey, prevTable)
    // NOTE: The fact that this listens to selection too means that an update to the table RELOADS the table, it's a little annoying but it's very convenient
    // If we ever want to persist the table, we can try removing "selection" from the array and fire tableTrigger manually
    //console.log("UPDATE TABLE FIRING")
  }, [selection, tableTrigger])

  // Update selection if we have a preSelect that only targets one layer
  // Save the user a click
  useEffect(() => {
    let multiSelectMap = preSelection.multiSelectMap
    if (multiSelectMap){
      let multiKeys = Object.keys(multiSelectMap)
      if (multiKeys.length === 1){
        // If the length is one, then we've only got a single layer here and we can select it directly
        multiKeys.forEach((key) => {
          // Set the key on state so we know what URL to grab
          setSelectKey(key)
          // Grab these features
          const lockAll = paverReg.role === 'Viewer' || (paverReg.role === 'Project Manager' && projectManagerEditLayers.indexOf(key) === -1)
          //console.log("LOCK2, the lockening", key, projectManagerEditLayers, paverReg.role)
          selectFeatures(multiSelectMap[key], editLayers[key], lockAll, form, view, Graphic, selection, setSelection)
        })
      }
    }
    //console.log("PRESELECT FIRING")
  }, [preSelection])

  useEffect(() => {
    // Tell the map to check for new shapes when the modal closes (post upload)
    if (showDropzone !== true && typeof showDropzone === "string"){
      // This means dropzone is sending a signal to update a layer, use this value
      //console.log("Updating", showDropzone)
      editLayers[showDropzone].refresh()
      triggerOrgUpdate([showDropzone])
    }
    if (showDropzone === true){
      // If we're showing the dropzone, make sure there aren't any selections in progress
      unselectAll(setSelection, setPreSelection)
      toggleEditPane(view, false)
    }
    //console.log("SHOW DROP FIRING")
  }, [showDropzone])

  useEffect(() => {
    //console.log("ESC KEY FIRING")
    // Bind unselect to Esc
    document.addEventListener("keydown", escFunction);
    return () => document.removeEventListener("keydown", escFunction);
  }, [selection, preSelection])


  useEffect(() => {
    //console.log("HOV KEY FIRING")
    // When selection or preselection changes, make sure the highlight is doing what it's supposed to
    // Zero out the previous selection
    for (let highlighted in highlight){
      let thisHighlight = highlight[highlighted]
      thisHighlight.remove()
    }
    highlight = []
    // If the multiselect is active, use it
    if (preSelection.multiSelectMap){
      // If selection is 0, then everything in the preselect gets highlighted
      Object.keys(preSelection.multiSelectMap).forEach((key) => {
        if (selection.length > 0 && key !== selectKey){
          // If there's also a selection, exit out if the selectKey doesn't match
          return
        }
        if (hoverKey && key !== hoverKey){
          // If the user is hovering, exit out if the selectKey doesn't match
          return
        }
        if (view){
          view.whenLayerView(preSelection.editLayers[key]).then((layerView) => {
            for (let selectItem in preSelection.multiSelectMap[key]){
              let thisItem = preSelection.multiSelectMap[key][selectItem]
              let newHighlight = layerView.highlight(thisItem.attributes.OBJECTID)
              newHighlight.itemID = thisItem.attributes.GlobalID
              highlight.push(newHighlight)
            }
          })
        }

      })
    } 
  }, [preSelection, hoverKey])

  // Use a side effect to create the map after React has rendered the DOM
  useEffect(() => {
    //console.log("INIT FIRING")
    /********** ARGCIS INSTANTIATION ***************/
    // This is the app token from validate.js
    esriConfig.apiKey = paverReg['token']

    // Create layers from the above
    let layersToShow = groupLayers

    // Create the map itself
    map = new Map({
      //basemap: "gray-vector", // Removed, we will define the default basemap in the gallery config
      layers: layersToShow
    })

    // And we show that map in a container
    const mapOptions = {
      map: map,
      // use the ref as a container
      container: mapEl.current,
      padding: { 
        right: 100, // Adjust for the right panels,
        left: 100,
        top: 100,
        bottom: 100,
      }
    }
    // If we've saved the user's position, use it
    if (cookies.paverMap){
      try {
        mapOptions.zoom = cookies.paverMap.zoom
        mapOptions.center = cookies.paverMap.center
      } catch (err){
        // It's ok
      }
    }
    view = new MapView(mapOptions)
    setView(view)

    // When view is ready, set extent to fit the service area
    view.when(() => {
      if (!cookies.paverMap){
        // If we don't have a cookie, use the extent from the config
        view.extent = extent
      }

      // UNCOMMENT IF WE NEED TO LOG THE VIEW SCALE
      // view.watch("scale", function(newValue) {
      //   console.log("VIEW SCALE", newValue)
      // });
    })
    /********** END ARGCIS INSTANTIATION ***************/

    /********** SELECT BY POLYGON ***************/

    // polygonGraphicsLayer will be used by the sketchviewmodel
    // show the polygon being drawn on the view
    const polygonGraphicsLayer = new GraphicsLayer({listMode: "hide"});
    map.add(polygonGraphicsLayer);

    // create a new sketch view model set its layer
    const sketchViewModel = new SketchViewModel({
      view: view,
      layer: polygonGraphicsLayer
    })

    let selectFunc = () => {
      sketchViewModel.create("rectangle")
    }

    addMapButton({
      id: "select-by-rectangle",
      title: "Rectangle select",
      icon: "/selection.svg",
      clickFunc: selectFunc,
      view
    })

    // Once user is done drawing a rectangle on the map
    // use the rectangle to select features on the map and table
    sketchViewModel.on("create", async (event) => {
      if (event.state === "complete") {
        // this polygon will be used to query features that intersect it
        const geometries = polygonGraphicsLayer.graphics.map(function(graphic){
          return graphic.geometry
        });
        const queryGeometry = await geometryEngineAsync.union(geometries.toArray())
        selectByRect(queryGeometry)
        // Remove the geo after the query is complete
        polygonGraphicsLayer.removeAll()
      }
    })

    // This function is called when user completes drawing a rectangle
    // on the map. Use the rectangle to select features in the layer and table
    function selectByRect(geometry) {
      // Bail if we're busy
      if (document.getElementById("drawing-help")) return

      // Match features to this geometry across all layers
      const multiSelectMap = {}
      const promiseStack = []
      Object.keys(editLayers).forEach((key) => {
        // Make sure the layer isn't hidden and we're close enough to see the lines
        // Also make sure the parent group isn't hidden!
        if (
          (editLayers[key].visible && editLayers[key].parent.visible) &&
          (view.scale <= editLayers[key].minScale || editLayers[key].minScale === 0)
        ){
          const queryPromise = new Promise((resolve, reject) => {
            // New improved pagination that does the query all in one request!
            let where = generateFilter(editLayers[key].url)
            // if (where){
            //   where += " AND "
            // }
            //where += "MemberId <> NULL" // Lines should be invisible if MemberId is null
            getPages(paverReg.token, editLayers[key], where || null, null, "*", geometry).then((resp) => {
              if (resp.length > 0){
                // If we have more than 0, add a key to the multiSelectMap
                multiSelectMap[key] = resp
              }
              resolve(resp)
            })
          })

          // Add promise to stack
          promiseStack.push(queryPromise)
        }
      })

      // Ok, now that we've built our map, let's decide what to do with it
      Promise.all(promiseStack).then((values) => {
        // Clear the selection if it's a new selection, but DON'T clear if it's just a misclick on nothing
        let clearSel = false
        for (let valueSet in values){
          let thisSet = values[valueSet]
          if (thisSet.length > 0){
            clearSel = true
          }
        }
        // If we have values, clear selection
        if (clearSel){
          unselectAll(setSelection)
          toggleEditPane(view, false)
          // Set the map as the preselect value, so the app knows what to do with it
          // TODO: We need to reconcile the new values with the old values if we want to handle incremental selects
          //console.log("selection[i] multiSelectMap", multiSelectMap)
          setPreSelection({multiSelectMap, editLayers, form, view, Graphic, selectFeatures})
        }
      })
    }
    /********** END SELECT BY POLYGON ***************/

    /********** PAN COOKIE ***************/
    // Mmmm delicious pan cookies
    const setMapCookie = (newValue) => {
      let cookieVal = {
        center: newValue.center,
        zoom: view.zoom
      }
      // Get zoom value from map
      setCookie('paverMap', cookieVal, { path: '/' })
    }
    // Let's debounce this though, it's firing too often
    const debouncedSetMapCookie = debounce(setMapCookie, 1000)
    // Set a cookie as user pans around on the map
    // This is used to keep the map in the same place when the user refreshes
    view.watch("extent", debouncedSetMapCookie)
    /********** END PAN COOKIE ***************/

    /********** SELECTION LOGIC ***************/
    view.on("click", async (event) => {
      let xyToLatLng = (coords) => {
        let newPoint = view.toMap({
          x: coords[0],
          y: coords[1]
        })
        return [newPoint.longitude, newPoint.latitude]
      }
      // Convert event to point object
      const point = view.toMap({
        x: event.x,
        y: event.y
      })
      let tolerance = 15 // Radius in xy around this point we should include
      let polygon = {
        type: "polygon",  // autocasts as new Polygon()
        rings: [ // Create an octogon
          xyToLatLng([event.x + tolerance, event.y]), // Right
          xyToLatLng([event.x + tolerance/1.5, event.y + tolerance/1.5]), // Right-Down
          xyToLatLng([event.x, event.y + tolerance]), // Down
          xyToLatLng([event.x - tolerance/1.5, event.y + tolerance/1.5]), // Left-Down
          xyToLatLng([event.x - tolerance, event.y]), // Left
          xyToLatLng([event.x - tolerance/1.5, event.y - tolerance/1.5]), // Left-Up
          xyToLatLng([event.x, event.y - tolerance]), // Up
          xyToLatLng([event.x + tolerance/1.5, event.y - tolerance/1.5]), // Right-Up
        ]
      }
      let polygonGraphic = new Graphic({
        geometry: polygon
      })
      // If we want to test it, we can actually add it
      // let symbol = {
      //   type: "simple-fill",  // autocasts as new SimpleFillSymbol()
      //   color: [ 51,51, 204, 0.9 ],
      //   style: "solid",
      //   outline: {  // autocasts as new SimpleLineSymbol()
      //     color: "white",
      //     width: 1
      //   }
      // }
      // polygonGraphic.symbol = symbol
      // polygonGraphicsLayer.graphics.add(polygonGraphic)

      // Now select using the polygon
      selectByRect(polygonGraphic.geometry)
    })

    /********** SELECT BY QUERY ***************/

    let queryFunc = () => {
      // Bail if we're busy
      if (document.getElementById("drawing-help")) return
      setShowQueryModal(true)
    }

    addMapButton({
      id: "select-by-query",
      title: "Search features",
      icon: "/loupe.svg",
      clickFunc: queryFunc,
      view
    })

    /********** END SELECT BY QUERY ***************/

    /********** FILTER FEATURES ***************/

    // let filterFunc = (e) => {
    //   console.log("RUN THE FILTER")
    //   e.currentTarget.classList.add("invert")
    // }

    // addMapButton({
    //   id: "filter-features",
    //   title: "Filter features",
    //   icon: "/filter-filled-tool-symbol.svg",
    //   clickFunc: filterFunc,
    //   view
    // })

    /********** END FILTER FEATURES ***************/

    // Grab the initial state of features on all layers to populate the menu
    // view.when(function() {
    //   console.log("VIEW WHEN")
    //   Object.keys(editLayers).forEach((key) => {
    //     return editLayers[key].when(function() {
    //       let query = editLayers[key].createQuery()
    //       // TODO: Target this query to just things this user can access

    //       // Query features off the layer (I think we can get even more targetted like:)
    //       // query.where = "STATE_NAME = 'Washington'";
    //       // query.outSpatialReference = { wkid: 102100 };
    //       // query.returnGeometry = true;
    //       // query.outFields = [ "CITY_NAME" ];
    //       return editLayers[key].queryFeatures(query)
    //     })
    //   })
    // })
    // .then(updateOrgData)

    /********** END SELECTION LOGIC ***************/

    // Also make sure our orgData is updated
    // NOTE: Everything moves a lot faster if we disable the layerView watch trigger and just do this once on load
    // Let's see if we can fire this manually to make up for it
    triggerOrgUpdate()

    // We need to create all the vector layers we'll have available
    let basemaps = [
      {
        id: "726b0f67d3034d63809ea35297ff9c5c", //Color Enhanced Grey Base Style
        thumbnailUrl: '/thumbs/color.png',
        title: "Default"
      },
      // {
      //   id: "5bf2a56f54d7455fbfee6de8ffb78731", //Terrain Base Style
      //   thumbnailUrl: '/thumbs/terrain.png',
      //   title: "Terrain"
      // },
      {
        id: "38071e08651b4ddeac217184924e8b2c", //Simplified Night World Street Map
        thumbnailUrl: '/thumbs/night.png',
        title: "Dark"
      },
      {
        id: "b8d6852a74b0438f8c714405cfe71c52", //Simplified Light Base Style
        thumbnailUrl: '/thumbs/light.png',
        title: "Light"
      },
      {
        id: "657d34122d7640e99ec9f70eaf993b31", //Community Base Style
        thumbnailUrl: '/thumbs/community.png',
        title: "Community"
      },
    ]

    // Create by looping
    let allBasemaps = []
    for (let basemap in basemaps){
      let thisBasemap = basemaps[basemap]
      const tiles = new VectorTileLayer({
        portalItem: {
          id: thisBasemap.id
        }
      })
      const mapForGallery = new Basemap({
        baseLayers: [
          tiles
        ],
        thumbnailUrl: thisBasemap.thumbnailUrl,
        title: thisBasemap.title
      })
      allBasemaps.push(mapForGallery)
    }
    allBasemaps = allBasemaps.concat([Basemap.fromId("topo-vector"), Basemap.fromId("hybrid")])

    // Create the basemap switcher
    let basemapGallery = new BasemapGallery({
      view: view,
      source: allBasemaps,
      activeBasemap: allBasemaps[0]
    })

    // Add a wrapping div so we can make it hidden on start
    let basemapWrapper = document.createElement('div')
    basemapWrapper.id = "basemap-wrapper"
    basemapWrapper.className = "esri-widget hidden-override"
    // Manually assign the container
    basemapGallery.container = basemapWrapper

    view.ui.add(basemapGallery, {
      position: "top-left"
    })

    let toggleBasemap = (e) => {
      // Bail if we're busy
      if (document.getElementById("drawing-help")) return

      let classList = e.currentTarget.classList
      if (classList.contains("show")){
        classList.remove("show")
        document.querySelector("#basemap-wrapper").classList.add("hidden-override")
      } else {
        // Start by clearing the select
        // if (latestSelection.length > 0){
        //   unselectAll(setSelection, setPreSelection)
        //   toggleEditPane(view, false)
        // }
        // Clear everytthing else
        clearUI()
        // Now show the panel
        classList.add("show")
        document.querySelector("#basemap-wrapper").classList.remove("hidden-override")
      }
    }

    addMapButton({
      id: "basemap-gallery",
      title: "Change basemap",
      icon: "/slides.png",
      clickFunc: toggleBasemap,
      view
    })

    // Create the layerlist, uncomment the created function to add dyanmic legend back
    const layerList = new LayerList({
      view: view,
      // TODO: This legend implementation is awful and so resource intensive -- we should remove the property below and insert it using just CSS later
      listItemCreatedFunction: (event) => {
        const item = event.item
        if (item.layer.type != "group") {
          // Only show legend for sublayers, not group
          const legend = new Legend({view: view, layerInfos: [item], respectLayerVisibility: false})
          item.panel = {
            content: legend, //"legend", // Taking over auto-creation so we can always show
            open: false,
            visible: true
          }
        }
      }
    })

    let toggleLayers = (e) => {
      // Bail if we're busy
      if (document.getElementById("drawing-help")) return

      let classList = e.currentTarget.classList
      if (classList.contains("show")){
        classList.remove("show")
        document.querySelector("#layerlist-wrapper").classList.add("hidden-override")
      } else {
        // Start by clearing the select
        // if (latestSelection.length > 0){
        //   unselectAll(setSelection, setPreSelection)
        //   toggleEditPane(view, false)
        // }
        // Clear everytthing else
        clearUI()
        // Now show the panel
        classList.add("show")
        document.querySelector("#layerlist-wrapper").classList.remove("hidden-override")
      }
    }

    addMapButton({
      id: "toggle-layers",
      title: "Toggle layer list",
      icon: "/layer.svg",
      clickFunc: toggleLayers,
      view
    })

    // Add a wrapping div so we can make it hidden on start
    let listWrapper = document.createElement('div')
    listWrapper.id = "layerlist-wrapper"
    listWrapper.className = "esri-widget hidden-override"
    // Manually assign the container
    layerList.container = listWrapper

    view.ui.add(listWrapper, {
      position: "top-left"
    })

    // Try adding some hardcoded symbology as soon as layerList is available
    layerList.when(() => {
      let selector = document.querySelector("span[title*='Paving Plan 20210919']")
      if (selector){
        let newNode = document.createElement('div')
        newNode.style.flex = "0 0 100%"
        newNode.innerHTML = `
          <div style='height: 3px; width: 50px; background: red;'></div>
        `
        selector.parentNode.insertBefore(newNode, selector.nextSibling);
      }
      //console.log("selectortest", layerList, selector)
    })

    // Add search bar
    const searchWidget = new Search({
      view: view,
      goToOverride: function(view, goToParams) {
        //console.log('goToParams.options', goToParams)
        // goToParams.options.duration = updatedDuration
        return view.goTo(goToParams.target, goToParams.options)
      },
      popupEnabled: false
    })

    // 4.22 fixed the problem that required my messy hack, so we can delete this!
    // let attachBlur = () => {
    //   let suggestionMenu = document.querySelector(".esri-search__suggestions-menu")
    //   if (suggestionMenu){
    //     suggestionMenu.addEventListener("mouseover", function blurBox(){
    //       let searchInput = document.querySelector(".esri-search__input")
    //       if (searchInput){
    //         searchInput.blur()
    //         suggestionMenu.removeEventListener("mouseover", blurBox)
    //       }
    //     })
    //   } else {
    //     // We tried to attach too early, wait for it
    //     setTimeout(() => {
    //       attachBlur()
    //     }, 1000)
    //   }
    // }

    // // Attach an event to remove focus from the search input because for some weird reason, the search suggestions won't work if the input is focused. This is not ideal, but it works.
    // searchWidget.on("suggest-complete", function(event){
    //   attachBlur()
    // })
    // searchWidget.on("search-focus", function(event){
    //   attachBlur()
    // })

    view.ui.add(searchWidget, {
      position: "top-left",
      index: 0
    })

    // Add scale to the map
    let scaleBar = new ScaleBar({
      view: view
    });
    // Add widget to the bottom left corner of the view
    view.ui.add(scaleBar, {
      position: "bottom-right"
    });
    

    /********** DRAW NEW FEATURE ***************/
    if (['Creator', 'Project Manager'].indexOf(paverReg.role) !== -1){
      draw = new Draw({
        view: view
      });

      // Add the forms
      let drawWrapper = document.getElementById('menu-draw-button')
      drawWrapper.addEventListener("click", () => {
        if (draw){
          // Clear other items on the map
          clearUI()
          // Make sure there aren't any selections in progress
          unselectAll(setSelection, setPreSelection)
          toggleEditPane(view, false)
          // Show helper text
          let info = document.getElementById("info")
          info.innerHTML = '<span id="drawing-help">Click to draw points for a line. Double click to complete the line.</span>' // NOTE: We are using this ID for functionality
          info.classList.remove('esri-hidden')
          draw.view.cursor = "crosshair"
          // Start polyline
          let action = draw.create("polyline", {mode: "click"})
          // fires when a vertex is added
          // focus the view to activate keyboard shortcuts for sketching
          view.focus()
          // listen polylineDrawAction events to give immediate visual feedback
          // to users as the line is being drawn on the view.
          action.on(
            [
              "vertex-add",
              "vertex-remove",
              "cursor-update",
              "redo",
              "undo",
              "draw-complete"
            ],
            (e) => {
              updateVertices(e, view, Graphic)
            }
          )
          // Listen for draw complete so we know when it's done
          action.on(
            [
              "draw-complete"
            ],
            (e) => {
              info.classList.add('esri-hidden')
              info.innerHTML = ""
              draw.view.cursor = "default"
              // console.log("e!", e, boundaryGeo)
              // TODO: Check if this is over boundary and delete if so
              let newGeo = finishLine(e, view, webMercatorUtils)
              setShowDropzone(newGeo)
            }
          )
        }
      })
      /********** END DRAW NEW FEATURE ***************/

      /********** FEEDBACK INFO ***************/
      let infoWrapper = document.createElement('div')
      infoWrapper.id = "info"
      infoWrapper.className = "esri-widget esri-hidden"
      infoWrapper.innerHTML = ""
      view.ui.add(infoWrapper, "top-right")
    }
    /********** END FEEDBACK INFO ***************/

    /********** DISCLAIMER ***************/

    let disclaimerWrapper = document.createElement('div')
    disclaimerWrapper.id = "disclaimer"
    disclaimerWrapper.className = "esri-widget 811-flex"
    disclaimerWrapper.innerHTML = `
      <div class="disclaim-box" rel="tooltip" data-helpsize="small" data-helptext="Maranta cannot guarantee the accuracy or reliability of data within the PaverOps application and does not assume liability for any damages caused by errors or omissions in the data, nor as a result of the failure of the data to function within the application. Maranta makes no warranty, expressed or implied, nor does the fact of distribution constitute such a warranty. PaverOps’ maps are for graphical purposes only and are not a substitute for an organization’s due diligence. Always contact 811 before digging.">
        <img src="/811.svg" />
        <a>Disclaimer</a>
      </div>
    `
    view.ui.add(disclaimerWrapper, "bottom-left")

    /********** END DISCLAIMER ***************/

    /********** FEATURE EDIT FORM ***************/
    let formWrapper = document.createElement('div')
    formWrapper.id = "update"
    formWrapper.className = "esri-widget esri-hidden"
    formWrapper.innerHTML = `
      <div id="form">
        <div class="question" style="float: right;" rel="tooltip" data-helptext="Use this form to edit attributes or delete all selected features. Adding a new attribute value or modifying an existing one will be saved to all selected features after “Update Feature” is clicked. Modifications to features not owned by your organization will be rejected."></div>
      </div>
    `
    // Only include edit buttons for creators
    if (['Creator', 'Project Manager'].indexOf(paverReg.role) !== -1){
      formWrapper.innerHTML += `
        <button class="esri-button" id="btn-update"></button>
        <button class="esri-button alert" id="btn-delete"></button>
        <button class="esri-button hollow" id="btn-export"></button>
      `
    }
    view.ui.add(formWrapper, "top-right")
    // Bind events attaches the tooltip to anything with the rel="tooltip" and a title
    Tooltip.bindEvents()

    form = new FeatureForm({
      container: "form",
      groupDisplay: "sequential", // only display one group at a time
      layer: editLayers[Object.keys(editLayers)[0]], // first in line default, but this will be overwritten 
      formTemplate: { // Autocasts to new FormTemplate
        title: "Feature details",
        //description: "Edit attributes",
        elements: []
      }
    })

    // Only include these functions for creators/PMs
    if (['Creator', 'Project Manager'].indexOf(paverReg.role) !== -1){
      let checkDiff = () => {
        // First check if we're a PM and this isn't a layer we have access to
        if (paverReg.role === "Project Manager" && projectManagerEditLayers.indexOf(latestSelectKey) === -1){
          setShowDiffModal("pm") // Special PM modal explaining what can't be edited
          return false
        }

        // Check this selection -- if there are items that don't match the user's org, throw a modal
        let diffOrg = 0
        for (let item in latestSelection){
          let thisItem = latestSelection[item]
          if (thisItem.attributes.MemberId !== paverReg.id){
            diffOrg++
          }
        }

        if (diffOrg === 0){
          // Run the function
          return true
        } else {
          if (diffOrg < latestSelection.length){
            setShowDiffModal("some")
          } else {
            setShowDiffModal("all")
          }
        }
        // If we made it to the end, return false
        return false
      }

      // Add update listener
      document.getElementById("btn-update").addEventListener("click", () => {
        if (latestSelection.length > 0) {
          let noDiff = checkDiff()
          if (noDiff){
            form.submit()
          }
        }
      })

      // Add delete listener
      document.getElementById("btn-delete").addEventListener("click", () => {
        if (latestSelection.length > 0) {
          let noDiff = checkDiff()
          if (noDiff){
            deleteFeatures(esriRequest, latestSelection, editLayers[latestSelectKey], setSelection, setPreSelection, view, () => {
              // Refresh this layer and update the org data when complete
              editLayers[latestSelectKey].refresh()
              triggerOrgUpdate([latestSelectKey])
            })
          }
        }
      })

      // Add export listener
      document.getElementById("btn-export").addEventListener("click", () => {
        if (latestSelection.length > 0) {
          let noDiff = checkDiff()
          if (noDiff){
            setShowExportModal(true)
          }
        }
      })

      // Listen to the feature form's submit event
      form.on("submit", () => {
        // Apply the updates
        applyAttributeUpdates(esriRequest, latestSelection, tableTrigger, setTableTrigger, editLayers[latestSelectKey], form, view, paverReg, setPreSelection).then((result) => {
          // We need to update in case any of the ordering values have changed
          // See if we need to update any other layers
          triggerOrgUpdate([latestSelectKey])
          // let boundLayer = false
          // for (let i in layerBinding){
          //   let layerList = layerBinding[i]
          //   if (layerList.indexOf(latestSelectKey) !== -1){
          //     // We found a bound layer, we need to update all of these
          //     boundLayer = true
          //     for (let j in layerList){
          //       let layer = layerList[j]
          //       // IF it exists, hopefully these names don't change
          //       if (editLayers[layer]){
          //         editLayers[layer].refresh()
          //         triggerOrgUpdate([layer])
          //       }
          //     }
          //   }
          // }
          // // If not bound, just do the normal refresh
          // if (!boundLayer){
          //   editLayers[latestSelectKey].refresh()
          //   triggerOrgUpdate([latestSelectKey])
          // }
        })
      })
    }
    
    /********** END FEATURE EDIT FORM ***************/
    // TODO FIX: The app crashes on hot reload, but ONLY when something is selected and this component is edited
    // NOTE: It looks like destroying on unmount seems to crash hot reload, so let's try just ... leaving it loaded
    // return () => {
    //   // clean up the map view
    //   if (!!map) {
    //     map.destroy()
    //     map = null
    //   }
    //   if (!!view) {
    //     view.destroy()
    //     view = null
    //   }
    //   if (!!form){
    //     form.destroy()
    //     form = null
    //   }
    //   if (!!polygonGraphicsLayer){
    //     polygonGraphicsLayer.destroy()
    //   }
    //   if (!!sketchViewModel){
    //     sketchViewModel.destroy()
    //   } 
    // }
  }, [])
  return <Fragment>
    <div style={{ height: "100%" }} ref={mapEl} />
    <div className={selectLoading !== false ? "loading-selection show" : "loading-selection"}>
      <div>Selecting {selectLoading !== false ? selectLoading.toLocaleString() : ""} features...</div>
      <br />
      {/*
      // We removed layerview so now we can implement this cancel button
      */}
      <button className="secondary exit-button" onClick={() => {
        globalState.selectionInProgress = false
        setSelectLoading(false)
      }}>Exit the selection</button>
      <LoadingBox />
    </div>
    {selection.length > 0 &&
      <div className="select-counter">
        <label>Features: {selection.length.toLocaleString()}</label>
        <label><a onClick={(e) => {
          escFunction(e, true)
        }}>Clear (Esc)</a></label>
      </div>
    }
  </Fragment>
}

export default EsriMap
